<template>
  <div id="pay" :style="`padding-bottom:${Height}px`">
    <!-- <div id="pay"> -->
    <el-steps :active="index" finish-status="success" :align-center="true">
      <el-step title="选择支付方式"></el-step>
      <el-step title="收银台"></el-step>
      <el-step title="支付完成"></el-step>
    </el-steps>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "pay",
  data() {
    return {
      index: 0,
      Height: "",
      // orderInformation: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val);
        val.name === "mode-of-payment" && (this.index = 0);
        val.name === "cashier" && (this.index = 1);
        val.name === "completion-of-payment" && (this.index = 2);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // console.log(window.innerHeight);
    // console.log(document.body.clientHeight);
    // this.Height = window.innerHeight - document.body.clientHeight;
    // console.log(this.Height);
    // Cookies.get("oder")
    //   ? ""
    //   : this.$router.push({
    //       name: `shopping-cart`,
    //     });
    // this.orderInformation = JSON.parse(Cookies.get("oder"));
    // this.orderInformation.totalAmount = decimals(
    //   this.orderInformation.totalAmount
    // );
    // console.log(this.orderInformation );
  },
  mounted() {
    window.innerHeight - document.body.clientHeight >= 0
      ? (this.Height = window.innerHeight - document.body.clientHeight + 60)
      : (this.Height = "");
  },
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#pay {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid #00aeeb;
  padding-bottom: 60px;
  ::v-deep .el-steps {
    width: 520px;
    padding: 30px 0;
    .el-step__head {
      .el-step__icon {
        width: 26px;
        height: 26px;
        .el-step__icon-inner {
          font-weight: 400;
        }
        i::before {
          color: rgba(0, 196, 92, 1);
          font-weight: 700;
        }
      }
    }
    .el-step__main {
      .is-success {
        color: rgba(0, 196, 92, 1);
      }
      .el-step__title {
        font-size: 14px;
      }
    }
    .is-success {
      border-color: rgba(0, 196, 92, 1);
    }
    .is-process {
      color: rgba(0, 196, 92, 1);
      border-color: rgba(0, 196, 92, 1);
      .is-text {
        background: rgba(0, 196, 92, 1);
        color: #fff;
      }
    }
  }
}
</style>